import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"

import { useMeta, appColors, device } from "utils"

import { PrimaryNav, AppStickyNav, MobileNav } from "src/components/navigation"

import { Layout, Seo } from "components/common"

import { AppFullFeatures, AppPricing } from "src/sections/app"

const AppFullFeaturesTemplate = ({ data: { app }, pageContext: { slug } }) => {
  const appFullFeatures = app.appFullFeatures
  const meta = useMeta(appFullFeatures)

  return (
    <Layout>
      <Seo {...meta} />

      <MobileNav slug={slug} />
      <StyledFullFeaturesTemplate slug={slug}>
        <PrimaryNav slug={slug} />
        <AppStickyNav
          disabled={app.disabled}
          slug={slug}
          salesforceAppexchangeUrl={app.salesforceAppexchangeUrl}
        />

        <AppFullFeatures {...appFullFeatures} />
      </StyledFullFeaturesTemplate>
      <PricingWrap slug={slug}>
        <AppPricing
          disabled={app.disabled}
          {...app.pricing}
          salesforceAppexchangeUrl={app.salesforceAppexchangeUrl}
        />
      </PricingWrap>
    </Layout>
  )
}

const StyledFullFeaturesTemplate = styled.div`
  --section-background-color: var(--lightBG);
  --tg-title-color: var(--card-shade_1);
  --tg-subtitle-color: var(--card-shade_1);
  --nav-sticky-link-color: var(--card-shade_1);
`

const PricingWrap = styled.section`
  --app-theme-color: ${({ slug }) => appColors[slug].dark};
  --app-gradient-color: ${({ slug }) => appColors[slug].textGradient};
  padding-top: var(--sp-48);

  @media ${device.laptop} {
    padding-top: 0;
  }
`

export const query = graphql`
  query FullFeatureFromApp($slug: String!) {
    app: contentfulApp(slug: { eq: $slug }) {
      ...App
    }
  }
`

export default AppFullFeaturesTemplate
